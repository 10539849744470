import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Sections/Hero'
import { getAssetUrl } from '../services/cdn'
import { Container, Row, Col, ButtonGroup, Button } from 'reactstrap'
import { Container as BlockContainer, Benefit } from '../components/Blocks'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='consumer/Apple-Coopmart-Wide.jpg' height={null} x={20} className="mt-4">
      <h1 className='display-1 pb-5 mt-5 text-white text-left'>&nbsp;</h1>
      <p>&nbsp;</p>
    </Hero>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC CONSUMER RETAILING</h1>
              <p>Meet the needs of  convenience oriented customers by providing a broad selection of high quality products and services at everyday fair prices, speedy transactions in a clean, safe and friendly environment.</p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Benefits of SIDC Products</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-circle fa-3x text-muted" title="Big Savings">Big Sale, Big Savings</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-stop-circle-o fa-3x text-muted" title="Patronage Refund">Purchases with Benefits through Patronage Refund</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-stop-circle fa-3x text-muted" title="Loyalty Program">Your buying frequency didn’t go unnoticed as SIDC values all of its customers</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-circle-o fa-3x text-muted" title="Mode of Transaction">You can purchase goods even without cash, just use your capital share.</Benefit>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <img alt="SIDC Flow" className="img-fluid d-block mx-auto" src={getAssetUrl("app/flow.png")} />
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-circle-o  fa-3x text-muted" title="Promotion Access">Your Membership is your pass to avail the latest promotion and discounts at all Coop Supermats, Coopmarts and One Stop Shops</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-stop-circle fa-3x text-muted" title="Valued Customer">You are our valued customer as well as the owner of the business you are patronizing into.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-stop-circle-o fa-3x text-muted" title="Purchase more">Patronizing is a way of helping the cooperative improved the lives of its membership</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit iconClass="fa fa-circle fa-3x text-muted" title="Grow with us">Just by availing SIDC Products and services, you are growing your capital share without you knowing it.</Benefit>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={3}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Provides a broad selection of high quality products and services at everyday fair prices</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col lg={{ size: 7 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">AGRICULTURE PRODUCTS</p>
            <div className="mb-0 pt-3 text-justify">
              <p>For 55 years, the Sorosoro Ibaba Development Cooperative (SIDC) — one of the largest agri-based cooperatives in the country, has been helping farmers and its members to be successfully empowered by increasing their participation in the agricultural value chain and enhancing their access to more lucrative and dynamic markets through collective action.</p>
              <p><b>Agriculture products are available here:</b><br /></p>
            </div>
            <ButtonGroup>
              <Button className="btn border-info btn-light">Coop Supermarket</Button>
              <Button className="btn border-info btn-light">Coopmarts</Button>
              <Button className="btn border-info btn-light">One Stop Shop</Button>
              <Button className="btn border-info btn-light">SIDC Feeds Outlets</Button>
            </ButtonGroup>
            <p className="mb-0 pt-3"><b>Agriculture Programs and Partnering are available for:</b><br /></p>
            <ButtonGroup>
              <Button className="btn btn-light border-primary">SIDC Paiwi Program</Button>
              <Button className="btn btn-light border-primary">KooPinoy SIDC Savings and Loans</Button>
            </ButtonGroup>
          </Col>
          <Col sm={{ size: 12 }} lg={{ size: 5 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/SIDC-corn2.jpg')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 2 }} lg={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/SIDC-fruits.jpg')}`} />
          </Col>
          <Col sm={{ order: 1 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">COOP SUPERMART, COOPMART, COOPMART JR. AND ONE CLICKMART</p>
            <div className="mb-0 pt-3 text-justify">
              <p>It offers a wide variety of reasonably priced commodities: groceries, electrical supplies and agricultural inputs at affordable prices. It is equipped with electronic cash registers utilizing a computer program. Members can buy from an outlet, which is stone-throw from their place without being bothered by heavy traffic. SIDC have 7 Coop Supermart located at  (1) Sorosoro, (2) Mahabang Parang in Batangas City, Batangas (3) Namuco Rosario Batangas, (4) Calatagan Batangas, (5) Sampaguita Lipa City Batangas, (6) Bansud, (7) Gloria Oriental Mindoro.</p>
              <p>The 10 Coop Marts are located at 1 (Tinga Labac), (2) Pallocan, (3) Conde Labac, (4)Pinamucan, (5) San Miguel in Batangas City, (6) Balanga, (7) Talaibon in Ibaan, Batangas (8) Bauan, Batangas, (9) Matungao, (10) Pinamalayan in Oriental Mindoro. </p>
              <p>The  41 One Stop Shop are located at (1) Mahacot, (2) Sta Rita, (3) Tulo, (4) Tabangao, (5) Gulod, (6) Dela Paz Pulot, Batangas City, Batangas, (7) Libjo, Batangas City (8) Antipolo, Lipa City, Batangas, (9) Taysan, San Jose, Batangas, (10) San Juan, Batangas (11) Calaca, Batangas; (12) San Mariano, (13) Bayanan, San Pascual, Batangas, (14) Talisay, Batangas (15) Tuy, Batangas, (16) San Agustin, Ibaan, Batangas (17) Bihis, Sta Teresita, (18) Mabini Batangas, (19) Alaminos, Laguna (20) Alabat, Quezon (21) Candelaria, Quezon (22) Sariaya, Quezon (23) Tiaong, Quezon, (24) Dagatan Taysan, (25) Bongabong Oriental Mindoro (26) Calapan, Oriental Mindoro, (27) San Jose, Occidental Mindoro , (28) Victoria, Oriental Mindoro, (29) Socorro, Oriental Mindoro (30) Magsaysay Occidental Mindoro (31) Calintaan, Occidental Mindoro (32) Jaen, Nueva Ecija (33) Zaragoza, Nueva Ecija (34) San Leonardo, Nueva Ecija, (35) Jamindan, Capiz (36), President Roxas, Capiz (37) Tapaz Capiz, (38)Lonoy Capiz, (39) Kalibo, Aklan, (40) Calabanga Camarines Sur, (41) Narra, Palawan.</p>
              <p>The 10 Coopmart Jr. are located at (1) Alangilan, (2) Dumantay, (3) Balagtas, (4) Sto Nino in Batangas City, (5) Bagong Pook, Lipa City, Batangas (6) Mabini, Lipa City, Batangas, (7) Sto Nino, San Pascual, Batangas, (8) Agoncillo,Batangas, (9) Cuenca, Batangas, (10) Mahanadiong Taysan, Batangas</p>
              <p>And 2 One Click Mart branch located at (1) Sorosoro Ilaya , (2) Balagtas  in Batangas City, Batangas.</p>
              <p><b>SIDC Products you can avail in Coop Supermart, Coopmart , Coopmart Jr. and One ClickMart</b><br /></p>
            </div>
            <ButtonGroup>
              <Button className="btn border-info btn-light">Groceries</Button>
              <Button className="btn border-info btn-light">Golden Lays Egg</Button>
              <Button className="btn border-info btn-light">Gintong Gawad</Button>
              <Button className="btn border-info btn-light">SIDC Feeds</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button className="btn border-info btn-light">RightVet</Button>
              <Button className="btn border-info btn-light">KoopLikas</Button>
              <Button className="btn border-info btn-light">KoopHardware</Button>
              <Button className="btn border-info btn-light">KoopHardware</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button className="btn border-info btn-light">Waismart Dishwasing Liquid</Button>
              <Button className="btn border-info btn-light">Waismart Cooking Oil</Button>
              <Button className="btn border-info btn-light">Waismart Fabcon</Button>
              <Button className="btn border-info btn-light">Waismart Laundry Detergent</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button className="btn border-info btn-light">Arf Arflicious Dog Food</Button>
            </ButtonGroup>
            <p className="mb-0 pt-3"><b>SIDC Partnering and Programs available for:</b><br /></p>
            <ButtonGroup>
              <Button className="btn btn-light border-primary">SIDC Kanego-sosyo Wholesale Program</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 2 }} lg={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/SIDC-Ecommerce.png')}`} />
          </Col>
          <Col sm={{ order: 1 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">E-COOPMART</p>
            <div className="mb-0 pt-3 text-justify">
              <p>The cooperative's e-commerce platform or online store which sells all SIDC Retailing Products to customers and members who want to purchase goods in the comfort of their home. Members enjoy the same patronage refund and other membership benefits in using this mode of shopping.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col lg={{ size: 7 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">HARDWARE FROM KOOPHARDWARE</p>
            <div className="mb-0 pt-3 text-justify">
              <p>SIDC’s Koop Hardware caters to the needs of its members and customers by selling quality hardware and constructions materials to them. Services can also be availed though the Construction Loan Program of KooPinoy SIDC Savings and Loan.</p>
              <p><b>Hardware products are available here:</b><br /></p>
            </div>
            <ButtonGroup>
              <Button className="btn border-info btn-light">Coop Supermarkets</Button>
              <Button className="btn border-info btn-light">Coopmarts</Button>
              <Button className="btn border-info btn-light">One Stop Shop</Button>
            </ButtonGroup>
            <p className="mb-0 pt-3"><b>Hardware Programs and Partnering are available for:</b><br /></p>
            <ButtonGroup>
              <Button className="btn btn-light border-primary">KooPinoy SIDC Savings and Loans</Button>
            </ButtonGroup>
          </Col>
          <Col sm={{ size: 12 }} lg={{ size: 5 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/plumbing.png')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 2 }} lg={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/rise_cl_1.jpg')}`} />
          </Col>
          <Col sm={{ order: 1 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">FEEDMILL</p>
            <div className="mb-0 pt-3 text-justify">
              <p>SIDC, has its own feed mill, an ISO 9001:2015 certified facility that produces an average of 9,000 bags per day. It uses state-of-the-art machineries for the production of mash, pellet and crumble feeds for swine, poultry, broilers and ruminants.</p>
              <p><b>SIDC Feedmill Products can be purchased here:</b><br /></p>
            </div>
            <ButtonGroup>
              <Button className="btn border-info btn-light">Coop Supermarket</Button>
              <Button className="btn border-info btn-light">Coopmarts</Button>
              <Button className="btn border-info btn-light">One Stop Shop</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button className="btn border-info btn-light">SIDC Feeds Outlets</Button>
              <Button className="btn border-info btn-light">SIDC Feedmill Sales Warehouse</Button>
            </ButtonGroup>
            <p className="mb-0 pt-3"><b>SIDC Feedmill Partnering and Programs available for:</b><br /></p>
            <ButtonGroup>
              <Button className="btn btn-light border-primary">KooPinoy SIDC Savings and Loans</Button>
              <Button className="btn btn-light border-primary">SIDC Paiwi Program</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
  </Layout>
)

export default IndexPage
